import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Message from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import Footer from '@solid-ui-blocks/Footer/Block02'

const NotFoundPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='404' indexed={false} />
      <Header content={content['header']} />
      <Divider space='4' />
      <Divider space='4' />
      <Message content={content['404']} reverse />
      <Divider space='4' />
      <Divider space='4' />
      <Footer content={content['footer2']} />
    </Layout>
  )
}

export const query = graphql`
  query miscRand404BlockContent {
    allBlockContent(filter: { page: { in: ["site/404", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default NotFoundPage
